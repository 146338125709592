import { useState } from 'react';
import SpeciesSearch from './SpeciesSearch';
import SpeciesResult from './SpeciesResult';
import './global.css';

function App() {
  const [isSearching, setIsSearching] = useState(false);
  const [species, setSpecies] = useState(null);
  const speciesColor = species ? species.error ? '#DDD' : species.status.color : '#44B687';

  return (
    <main className='wrapper' style={{ backgroundColor: speciesColor }}>
      <div className='animation-hider' style={{ backgroundColor: speciesColor }}></div>
      <div className='card'>
        <SpeciesSearch species={species} setSpecies={setSpecies} isSearching={isSearching} setIsSearching={setIsSearching} speciesColor={speciesColor} />
        <div className='animation-wrapper' style={species ? { transform: 'translateY(0)' } : { transform: 'translateY(-100%)' }}>
          {!isSearching ?
            <SpeciesResult species={species} speciesColor={speciesColor} />
            : null}
        </div>
      </div>
    </main>
  );
}

export default App;
