import { useState, useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import { getSearchResults, getSpecies } from './species';

function SpeciesSearch({ species, setSpecies, isSearching, setIsSearching, speciesColor }) {
  const [userInput, setUserInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputElement = useRef(null);
  const autosuggestElement = useRef(null);

  const getSearchResultsDebounced = useMemo(() => debounce(async query => {
    try {
      const results = await getSearchResults(query);
      setSearchResults(results);
      setSelectedSearchResult(0);
    } catch (err) {
      setSearchResults({ error: err.message });
      setSelectedSearchResult(null);
    }
  }, 500), []);

  function handleInput(e) {
    setIsSearching(true);
    setSearchResults([]);
    const query = e.target.value;
    setUserInput(query);
    getSearchResultsDebounced(query);
  }

  function handleSearchNavigation(e) {
    if (e.key === 'Escape') {
      setIsSearching(false);
      return;
    }

    if (searchResults.length && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      const lastIndex = searchResults.length - 1;
      const i = selectedSearchResult;
      let newSelectedSearchResult;

      if (e.key === 'ArrowUp') {
        newSelectedSearchResult = i === null ? lastIndex : Math.max(i - 1, 0);
      } else if (e.key === 'ArrowDown') {
        newSelectedSearchResult = i === null ? 0 : Math.min(i + 1, lastIndex);
      }

      setSelectedSearchResult(newSelectedSearchResult);
      autosuggestElement.current.children.item(newSelectedSearchResult).scrollIntoView(false);
    }
  }

  async function handleSubmit(e, i = null) {
    if (e) e.preventDefault();
    setIsLoading(true);

    if (searchResults.length) {
      inputElement.current.blur();
      const selectedPageId = i !== null ? searchResults[i].pageId : selectedSearchResult === null ? searchResults[0].pageId : searchResults[selectedSearchResult].pageId;

      try {
        const response = await getSpecies(selectedPageId);
        setSpecies(response);
        setUserInput(response.name);
      } catch (err) {
        setSpecies({ error: err.message });
      }
    }

    setIsLoading(false);
  }

  return (
    <>
      <form onSubmit={e => handleSubmit(e)} data-testid='form'>
        <input ref={inputElement} className='user-input' name='species-input' type='text' placeholder='Enter a species' value={userInput} aria-label='Species search' role='combobox' aria-expanded={isSearching} aria-autocomplete='both' aria-controls='autosuggest' aria-owns='autosuggest' aria-activedescendant={selectedSearchResult === null ? '' : 'autosuggest-' + (selectedSearchResult + 1)} onChange={e => handleInput(e)} onFocus={e => { e.target.select(); setIsSearching(true); }} onBlur={e => setIsSearching(false)} onKeyDown={e => handleSearchNavigation(e)} />
        {isLoading ?
          <div className='loader'></div> :
          <button className='submit-button' type='submit' aria-label='Submit' disabled={!searchResults.length}>
            <span />
            <span />
          </button>
        }
      </form>
      {isSearching ?
        <div ref={autosuggestElement} id='autosuggest' className='autosuggest' role='listbox'>
          {searchResults.error ?
            <div className='search-suggestion'>
              <h3 className='search-suggestion-title'>Error</h3>
              <p className='search-suggestion-snippet'>{searchResults.error}</p>
            </div> :
            !searchResults.length ?
              <div className='search-suggestion'>
                <h3 className='search-suggestion-title'>No search results</h3>
                <p className='search-suggestion-snippet'>Please ensure that you typed the species or common name in full or try another. If a species does not appear, it may be because it is not on the IUCN Red List of Threatened Species.</p>
              </div> :
              searchResults.map((searchResult, i) => <div key={searchResult.pageId} id={'autosuggest-' + (i + 1)} className='search-suggestion' role='option' aria-selected={i === selectedSearchResult} onClick={e => handleSubmit(e, i)} onMouseDown={e => e.preventDefault()} onMouseEnter={() => setSelectedSearchResult(i)} onMouseLeave={() => setSelectedSearchResult(null)} style={i === selectedSearchResult ? { color: speciesColor, backgroundColor: '#222', cursor: 'pointer' } : null}>
                <h3 className='search-suggestion-title'>{searchResult.title}</h3>
                <p className='search-suggestion-snippet'>{searchResult.snippet}</p>
              </div>)
          }
        </div>
        : null}
    </>
  );
}

export default SpeciesSearch;