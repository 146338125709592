import { conservationStatuses } from "./species";

function SpeciesResult({ species, speciesColor }) {
  return species ?
    <div className='results-wrapper' data-testid='results-wrapper'>
      {species.error ?
        <>
          <div className='error-icon'>
            <div className='exclamation exclamation-top'></div>
            <div className='exclamation exclamation-bottom'></div>
          </div>
          <p className='error-message'>{species.error}</p>
        </> :
        <>
          <p className='description'>{species.description} <a href={species.url} target='_blank' rel="noreferrer">Learn more</a></p>
          <div className='conservation-bar'>
            {conservationStatuses.map(status => ['DD', 'NE'].includes(status.key) ? null : <div key={status.key} className='conservation-circle' style={status.key === species.status.key ? { color: speciesColor, backgroundColor: '#222' } : null}>{status.key}</div>)}
          </div>
          <div className='conservation-status'>
            {species.status.name}
          </div>
        </>
      }
    </div>
    : null;
}

export default SpeciesResult;