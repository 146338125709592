export const conservationStatuses = [
  {
    key: 'EX',
    name: 'Extinct',
    wikiSelector: 'a[title="Extinct"], a[title="Extinction"]',
    color: '#AF0026'
  },
  {
    key: 'EW',
    name: 'Extinct in the wild',
    wikiSelector: 'a[title="Extinct in the Wild"]',
    color: '#AF0026'
  },
  {
    key: 'CR',
    name: 'Critically endangered',
    wikiSelector: 'a[title^="Critically Endangered"]',
    color: '#E2DB00'
  },
  {
    key: 'EN',
    name: 'Endangered',
    wikiSelector: 'a[title^="Endangered"]',
    color: '#E2DB00'
  },
  {
    key: 'VU',
    name: 'Vulnerable',
    wikiSelector: 'a[title^="Vulnerable"]',
    color: '#E2DB00'
  },
  {
    key: 'NT',
    name: 'Near threatened',
    wikiSelector: 'a[title^="Near Threatened"]',
    color: '#44B687'
  },
  {
    key: 'CD',
    name: 'Conservation dependent',
    wikiSelector: 'a[title^="Conservation Dependent"]',
    color: '#44B687'
  },
  {
    key: 'LC',
    name: 'Least concern',
    wikiSelector: 'a[title^="Least Concern"]',
    color: '#44B687'
  },
  {
    key: 'DD',
    name: 'Data deficient',
    wikiSelector: 'a[title^="Data Deficient"]',
    color: '#DDD'
  },
  {
    key: 'NE',
    name: 'Not evaluated',
    wikiSelector: 'a[title^="Not Evaluated"]',
    color: '#DDD'
  }
];

// Get species search results from Wikipedia
export async function getSearchResults(query) {

  const data = await queryWikipedia({
    action: 'query',
    list: 'search',
    srlimit: 5,
    srsearch: `deepcat:Species_by_IUCN_Red_List_category+intitle:${query}`
  });

  return data.query.search.map(x => ({
    pageId: x.pageid,
    title: x.title,
    snippet: createSnippet(stripHtml(x.snippet))
  }));
}

// Get name, description and conservation status of a species from Wikipedia
export async function getSpecies(pageId) {
  
  const data = await queryWikipedia({
    action: 'parse',
    pageid: pageId
  });

  // Parse Wikipedia content as DOM object
  const content = document.createElement('div');
  content.innerHTML = data.parse.text['*'];

  // Get species information from infobox
  const infobox = content.querySelector('.biota');

  if (!infobox) {
    throw new Error(`Sorry, no information was found on this species. Please ensure that you entered the name correctly or try another. If a species does not appear, it may be because it is not on the IUCN Red List of Threatened Species.`);
  }

  const species = {
    pageId: data.parse.pageid,
    url: `https://en.wikipedia.org/?curid=${data.parse.pageid}`,
    name: data.parse.title,
    description: createSnippet(infobox.nextElementSibling.textContent),
    status: null
  };

  // Find conservation status from Wikipedia DOM object
  for (let status of conservationStatuses) {
    if (infobox.querySelector(status.wikiSelector)) {
      species.status = status;
      break;
    }
  }

  if (!species.status) {
    throw new Error(`Sorry, no conservation information was found on this species. Please try another!`);
  }

  return species;
}

// Query the Wikipedia API
async function queryWikipedia(parameters = {}) {
  let response;
  let data;
  const queryString = Object.entries(parameters).reduce((a, [key, value]) => `${a}&${key}=${value}`, '');

  try {
    response = await fetch(`https://en.wikipedia.org/w/api.php?origin=*&format=json${queryString}`);
    if (!response.ok || !response.json)
      throw new Error(`${response.status} - ${response.statusText}`);
  } catch (err) {
    throw new Error(`Sorry, information could not be accessed due to an error: ${err.message}. Please try again later.`);
  }

  data = await response.json();
  return data;
}

// Strip HTML tags from a string
function stripHtml(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent;
}

// Create a shortened snippet from a string
export function createSnippet(str, chars = 330) {
  if (typeof str !== 'string')
    throw new Error('Provided input is not a string');

  // Remove any citations
  str = str.replace(/\[(.*?)\]/gi, '');

  // Take the first [chars] characters, trim any whitespace and add ellipses
  str = str.trim();
  str = str.substring(0, chars);
  str = str[str.length - 1] === '.' ? str += '..' : str += '...';
  return str;
}